// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("bootstrap")
require("jquery")

import $ from 'jquery';
global.$ = jQuery;

import "cocoon-js";
import "@fortawesome/fontawesome-free/js/all";

require("moment/locale/fr")
require("tempusdominus-bootstrap-4")


window.depends_on = function(e) {
	var depends_on_choice = e.value;

  $('*[data-depends-on-field='+ $(e).data("field") +']').each(function( index ) {
  	if (depends_on_choice != $(this).data("depends-on-value")) {
  		if($(this).children().hasClass("datepicker") || 
  			 $(this).children().hasClass("timepicker")) {
  			$(this).children().datetimepicker('disable')
  		}

  		$(this).children().prop('disabled', true);
  		$(this).css({opacity: 0.5})
  	} else {
  		if($(this).children().hasClass("datepicker") || 
  			 $(this).children().hasClass("timepicker")) {
  			$(this).children().datetimepicker('enable')
  		}
  		$(this).children().prop('disabled', false);
  		$(this).css({opacity: 1.0})
  	}
  });
}

function init() {
	$('.datepicker').datetimepicker({ format: 'DD/MM/YYYY' });
	$('.timepicker').datetimepicker({ format: 'HH:mm' });
}

$(document).on("turbolinks:load", function() {
	init();
});

$(document).ready(function() {
	init();
});